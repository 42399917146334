import { BsFillStarFill } from 'react-icons/bs'

export const reviewItems = [

    {

        reviewID: '01',
        cName: 'reviewItem',
        sourceURL: 'https://www.homeadvisor.com/rated.Loyagranite.109184108.html',
        firstName: 'Rebeca',
        lastName: 'F.',
        date: '04/20/2023',
        ratingValue: '5.0',
        reviewIcon: <BsFillStarFill />,
        reviewTitle: 'Install Stone Slab Countertops',
        review: 'Phenomenal experience!'
    },
    {

        reviewID: '02',
        cName: 'reviewItem',
        sourceURL: 'https://www.homeadvisor.com/rated.Loyagranite.109184108.html',
        firstName: 'Brad',
        lastName: 'G',
        date: '04/14/2023',
        ratingValue: '5.0',
        reviewIcon: <BsFillStarFill />,
        reviewTitle: 'Install Countertops',
        review: 'Diego was great! I had two bathroom vanities done by them and they both turned out great'
    },
    {

        reviewID: '03',
        cName: 'reviewItem',
        sourceURL: 'https://www.homeadvisor.com/rated.Loyagranite.109184108.html',
        firstName: 'Marty',
        lastName: 'M',
        date: '04/07/2023',
        ratingValue: '5.0',
        reviewIcon: <BsFillStarFill />,
        reviewTitle: 'Remodel a Kitchen',
        review: 'Diego is professional and knowledgeable about the business and industry he works in. His crew was prompt to arrive The demo day and install day. They were very personable and worked fast the cleaned up after they were done. I am so happy with my new counter tops and glad I chose them to do it. Their whole process from first contact to day of install was fast! Price point is better then their competitors they make you feel like family. If I could give them 10 stars I would. I highly recommend Loya granite llc if your looking for new counter tops.'
    },
    {

        reviewID: '04',
        cName: 'reviewItem',
        sourceURL: 'https://www.homeadvisor.com/rated.Loyagranite.109184108.html',
        firstName: 'Christina',
        lastName: 'Santiago',
        date: '01/13/2023',
        ratingValue: '5.0',
        reviewIcon: <BsFillStarFill />,
        reviewTitle: 'Install Laminate Countertops',
        review: 'Very professional to work with. Finished product is beautiful and I look forward to using them on our next project.'
    },
    {
        reviewID: '05',
        cName: 'reviewItem',
        sourceURL: 'https://www.homeadvisor.com/rated.Loyagranite.109184108.html',
        firstName: 'Sandy',
        lastName: 'K',
        date: '01/13/2023',
        ratingValue: '5.0',
        reviewIcon: <BsFillStarFill />,
        reviewTitle: 'Install Solid Surface Countertops',
        review: 'Amazing Company! They were incredibly easy to work with and punctual. They had great communication skills and did a beautiful job. I would highly recommend them!'
    },
    {
        reviewID: '06',
        cName: 'reviewItem',
        sourceURL: 'https://www.homeadvisor.com/rated.Loyagranite.109184108.html',
        firstName: 'Ann',
        lastName: 'Roeber',
        date: '01/06/2023',
        ratingValue: '5.0',
        reviewIcon: <BsFillStarFill />,
        reviewTitle: 'Install Stone Slab Countertops',
        review: 'This is THE place to go for countertops! Absolutely wonderful customer service. Fast, continual contact, and amazing installation. We couldn\'t be happier!'
    },
    {
        reviewID: '07',
        cName: 'reviewItem',
        sourceURL: 'https://www.homeadvisor.com/rated.Loyagranite.109184108.html',
        firstName: 'Stefani',
        lastName: 'W',
        date: '12/02/2022',
        ratingValue: '5.0',
        reviewIcon: <BsFillStarFill />,
        reviewTitle: 'Install Solid Surface Countertops',
        review: 'I am very grateful for the amazing job they did in my kitchen.'
    },
    {
        reviewID: '08',
        cName: 'reviewItem',
        sourceURL: 'https://www.homeadvisor.com/rated.Loyagranite.109184108.html',
        firstName: 'Kenja',
        lastName: 'S',
        date: '12/02/2022',
        ratingValue: '5.0',
        reviewIcon: <BsFillStarFill />,
        reviewTitle: 'Remodel a Bathroom',
        review: 'They were on time, they were very professional, answered every question and the results were wonderful.'
    },
    {
        reviewID: '09',
        cName: 'reviewItem',
        sourceURL: 'https://www.homeadvisor.com/rated.Loyagranite.109184108.html',
        firstName: 'Natalie',
        lastName: 'M.',
        date: '11/30/2022',
        ratingValue: '5.0',
        reviewIcon: <BsFillStarFill />,
        reviewTitle: 'Install Laminate Countertops',
        review: 'His company was very professional, they were extremely easy to work with, went above and beyond and did an excellent job on our kitchen. I would definitely use them again.'
    },

]