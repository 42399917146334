import React from 'react'
import About from '../../Components/About/About'

function Home() {
    return (
        <div>
            < About />
        </div>
    )
}

export default Home