
import graniteImg from '../../Components/Modal/assets/granite-texture-blue.jpg';
import marbleImg from '../../Components/Modal/assets/marble-texture-white.jpg';
import quartziteImg from '../../Components/Modal/assets/quartzite-texture-yellow.jpg';
import quartzImg from '../../Components/Modal/assets/quartz-texture-yellow.jpg';
export const materials =[
    {
    itemCoverExample: graniteImg,
    itemName:"Granite",
    itemDesc:"Natural Stone",
    itemMaintenance:"Medium maintenance required",
    itemSealRequirement:" Sealer required Recommended once every six months",
    itemMaintenance:"Medium maintenance required " ,
    itemIdeal: " Ideal for kitchen, bathrooms and fireplaces ", 
    itemOutsideCapability:" .Ideal for outside projects, weather resistant Grills, or outer countertop " ,
    itemValueDesc:"  Prices vary on the highest grade of granite design and color",
    itemValue:"Average slab cost 1500.00"
    },
    {
        itemCoverExample:marbleImg,
        itemName:"Marble",
        itemDesc:"Natural Stone ",
        itemMaintenance:" High maintenance required  " ,
        itemSealRequirement: "Sealer required (Recommended once every six months) " ,
        itemMaintenance:" High maintenance required  " ,
        itemIdeal: "Ideal for bathrooms, walls or fireplaces.", 
        itemOutsideCapability:"(Not recommended for kitchens)  Not ideal for outside projects.  ", 
        itemValueDesc:"  Marble prices are one of the highest natural stones available.",
        itemValue:" Average slab cost 2200.00 "
    },
    
{
    itemCoverExample:quartziteImg,
    itemName:"Quartzite", 
    itemDesc: ".Natural Stone(Natural quartz)",
    itemSealRequirement:".Sealer required (Recommended once every six months.)",
    itemMaintenance:". Medium maintenance required",
    itemIdeal:". Ideal for kitchen, bathrooms and fireplaces",
    itemOutsideCapability:". Ideal for outside projects, weather resistant(Grills, or outer countertops)",
    itemValueDesc:". Prices on quartzite are some of the highest of natural stone",
    itemValue:". Average slab cost 2300.00"
   },
   {
    itemName:"Quartz",
    itemCoverExample:quartzImg,
    itemDesc:".Laboratory fabricated (Unnatural stone)",
    itemSealRequirement:". No sealer required",
    itemMaintenance:". Low maintenance required (regular cleaner will do)",
    itemIdeal:"Ideal for bathrooms, walls, and kitchens. (Not ideal for fireplaces)",
    itemOutsideCapability:". Not Ideal for outside projects. Not weather resistant.",
    itemValueDesc: ". Prices on quartz are very flexible depending on the brand or design.",
    itemValue:". Average Slab cost 1300.00" }    
]

