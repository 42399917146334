import React from 'react'
import ProjectsView from '../../Components/Projects/Projects'

function Projects() {
    return (
        <div>
            <ProjectsView />

        </div>
    )
}

export default Projects